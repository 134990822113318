import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useFollowCompanyQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (company: string) =>
      $apiFetch(`/api/company/${company}/follow`, {
        method: "POST",
      }),
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "company" &&
          (query.queryKey[1] === "search" || query.queryKey[1] === variables),
      }),
  });
};
